import React from "react";
import styled from "styled-components";

const Content = styled.footer`
	color: ${props => props.theme.colors.secondary};
	text-align: center;
	font-size: 0.9rem;
	padding-top: 3rem;
	padding-bottom: 3rem;
	background: ${props => props.theme.colors.bg};
	a {
		color: #fff;
	}
`;

const Contact = styled.div`
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	color: ${props => props.theme.colors.secondary};
	text-align: center;
	font-size: 0.9rem;
	margin: 3rem auto;
	padding: 1em 0;
	background: ${props => props.theme.colors.bg};
	max-width: 25%;
	a {
		color: #fff;
	}
	@media (max-width: ${props => props.theme.breakpoints.s}),
		(max-device-width: ${props => props.theme.breakpoints.s}) {
		max-width: 65%;
	}
`;

const Footer = () => (
	<Content>
		<Contact>
			dragankurucic@gmail.com
			<br />
			<br />
			+381.63.58.67.47
		</Contact>
		&copy; Dragan Kurucić Baroque Underground 2019. powerd by{" "}
		<a href="http://digitalartsagency.com" target="_blank">
			DigitalArts
		</a>
	</Content>
);

export default Footer;
