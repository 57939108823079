import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Link from "gatsby-link";

const Wrapper = styled.div`
	background: #000;
	display: flex;
	position: relative;
	@media (max-width: ${props => props.theme.breakpoints.s}),
		(max-device-width: ${props => props.theme.breakpoints.s}) {
		margin-bottom: 7em;
	}
`;

const Content = styled.div`
	margin: 0 auto;
	max-width: ${props => props.theme.maxWidths.general};
	padding: 3rem 1.0875rem 3rem 1.0875rem;
	color: ${props => props.theme.colors.secondary};
	text-align: center;
	height: 400px;
	@media (max-width: ${props => props.theme.breakpoints.s}) {
		height: 200px;
	}
`;

const Name = styled.h1`
	margin: 1rem 0 0.25rem 0;
	color: #9f8958;
	text-align: center;
`;

const Location = styled.div`
	font-size: 1.1rem;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Navigation = styled.nav`
	margin-top: 1em;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);

	ul {
		margin: 0.5em auto;
		list-style-type: none;
		font-size: 0.9rem;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
	}
	li {
		list-style-type: none;
		display: flex;
		margin-right: 1em;
	}
	a {
		color: rgba(255, 255, 255, 0.5);
	}
`;

const Header = ({ name, location }) => (
	<Wrapper>
		<Content>
			<Name>{name}</Name>
			<Location>{location}</Location>
			<Navigation>
				<nav>
					<ul>
						<li>
							<Link to="/">Home</Link>
						</li>
						<li>
							<Link to="/exibition">The Exibition</Link>
						</li>
						<li>
							<Link to="/o-autoru">The Author</Link>
						</li>
					</ul>
				</nav>
			</Navigation>
		</Content>
	</Wrapper>
);

export default Header;

Header.propTypes = {
	avatar: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	location: PropTypes.string.isRequired,
	socialMedia: PropTypes.array.isRequired
};
