module.exports = {
	pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

	siteTitle: "Baroque Underground", // Navigation and Site Title
	siteTitleAlt: "Baroque Underground - Dragan Kurucić", // Alternative Site title for SEO
	siteTitleShort: "Dragan Kurucić", // short_name for manifest
	siteHeadline: "Baroque Underground", // Headline for schema.org JSONLD
	siteUrl: "https://dreamy-hamilton-bc80ea.netlify.com/", // Domain of your site. No trailing slash!
	siteLanguage: "en", // Language Tag on <html> element
	siteLogo: "/logos/logo.png", // Used for SEO and manifest
	siteDescription:
		"The camera represents an extension of the photographer’s view receives a new affirmation and amendment in Dragana Kurucić’s series Baroque Underground.",
	author: "DigitalArts", // Author for schema.org JSONLD

	// siteFBAppID: '123456789', // Facebook App ID - Optional
	userTwitter: "@Baroque Underground", // Twitter Username
	ogSiteName: "BAROQUE UNDERGROUND", // Facebook Site Name
	ogLanguage: "en_US", // og:language
	googleAnalyticsID: "UA-47519312-4",

	// Manifest and Progress color
	themeColor: "#3498DB",
	backgroundColor: "#2b2e3c",

	// Your information
	avatar: "/logos/logo.png",
	name: "BAROQUE UNDERGROUND",
	location: "Dragan Kurucić",
	socialMedia: [
		{
			url: "https://twitter.com/",
			name: "Twitter"
		},
		{
			url: "https://www.instagram.com/",
			name: "Instagram"
		},
		{
			url: "https://www.lekoarts.de/",
			name: "Homepage"
		}
	]
};
